import { useForm } from "react-hook-form";
import { TemplateForm } from "../../../common/TemplateForm";
import { Input } from "../../../common/elementForms/Inputs";
import { usePostCreateSession } from "../../../../hooks/useFetch/usePostCreateSession";
import { useGetProducts } from "../../../../hooks/useFetch/useGetProducts";
import { SEO } from "../../../../seo/SEO";

export const ZettabyteForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ mode: "onChange" });

  const { products } = useGetProducts();
  const product = products.find((p) => p.name === "Zettabyte");
  const { id, price, currency } = product?.prices[0] || {};

  const { sendData, loading, serverError } = usePostCreateSession();

  return (
    <>
      <SEO
        title="Suscripción - Zettabyte"
        description="Formulario de suscripción a Zettabyte"
      />
      <section className="min-h-screen flex flex-col justify-center items-center sm:py-32 py-24">
        <div className="lg:w-[350px] sm:w-[250px] w-[190px] mb-5">
          <img
            src="/images/home/suscription/img-zetta.webp"
            alt="Zettabyte"
            className="w-full object-cover"
          />
        </div>

        <div className="space-y-3 mb-10">
          <h4 className="md:text-3xl text-2xl font-semibold text-center text-colorGray">
            {" "}
            Suscribete al Newsletter de{" "}
            <span className="text-colorBlue">Zettabyte</span>, <br /> por tan
            solo{" "}
            <span className="text-colorBlue">
              ${price / 100} {currency}
            </span>{" "}
            al mes.
          </h4>
          <p className="text-center">
            Ingresa tu correo electrónico y obten{" "}
            <span className="text-colorBlue font-bold">15 dias gratis</span>.
          </p>
        </div>

        <div className="xl:w-2/3 lg:w-[90%] md:w-[95%] sm:w-[85%] w-full">
          <TemplateForm
            onSubmit={handleSubmit((data) => sendData(data, reset, id))}
            textButton="Suscribirse"
            classButton="btn-bg-yellow sm:w-[200px] w-[220px] text-colorGray"
            colorLoader="border-colorGray border-t-colorYellow"
            containerButton="flex w-full justify-center items-center"
            styleIsValid="hover:border-2 hover:border-colorBlue hover:bg-transparent hover:text-colorGray"
            isValid={isValid}
            loading={loading}
          >
            <div className="flex justify-center w-full">
              <Input
                name="userEmail"
                type="email"
                placeholder="Escribe aquí tu correo electrónico"
                textLabel="Correo electrónico"
                register={register}
                errors={{ ...errors, serverError }}
                classInput="input-line-blue text-colorGray md:text-left text-center"
                classLabel="text-colorGreen hidden"
                containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
              />
            </div>
          </TemplateForm>
        </div>
      </section>
    </>
  );
};
