export const BannerHome = () => {
  return (
    <section className="min-h-screen bg-colorGray flex lg:flex-row flex-col items-center justify-center lg:space-y-0 space-y-10 lg:space-x-10 py-32">
      <div className="2xl:max-w-xl">
        <h2 className="text-white sm:text-left text-center">
          ¿Te cuesta estar al día con los temas políticos, financieros y
          tecnológicos?{" "}
        </h2>
      </div>

      <div className="xl:max-w-xl lg:max-w-md space-y-10">
        <p className="text-colorYellow font-semibold">
          ¡Recibe nuestras noticias por correo electrónico!
        </p>
        <p className="text-white">
          Ofrecemos documentos exhaustivos que{" "}
          <span className="text-colorYellow">analizan </span>y{" "}
          <span className="text-colorYellow">describen</span> temas de interés
          político, financiero, tecnológico, entre otros, a partir de la
          información de{" "}
          <span className="text-colorYellow">distintos medios</span> como la
          radio, televisión, prensa y web.
        </p>
        <div className="w-full flex sm:justify-start justify-center">
          <a
            href="#suscripcion"
            className="btn-bg-yellow text-colorGray hover:text-colorYellow rounded-lg font-semibold w-40 h-11 flex items-center justify-center transition-colors ease-in-out duration-300"
          >
            Suscribete
          </a>
        </div>
      </div>
    </section>
  );
};
