import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../assets/icons/SocialIcons";
import { Isotipo } from "../../assets/identity/Isotipo";

export default function Footer() {
  return (
    <footer className="flex lg:flex-row flex-col lg:justify-between lg:items-end items-center bg-colorGray py-16 lg:space-y-0 space-y-10">
      <div className="flex xl:flex-row flex-col justify-center items-center xl:space-x-3 xl:space-y-0 space-y-3">
        <div className="w-10">
          <Isotipo fill="#fff" />
        </div>
        <small className="text-white text-base">
          ©2020, Todos los derechos reservados.
        </small>
      </div>
      <div className="flex space-x-5">
        <InstagramIcon fill="#fff" />
        <FacebookIcon fill="#fff" />
        <TwitterIcon fill="#fff" />
        <LinkedinIcon fill="#fff" />
      </div>

      <div className="flex xl:flex-row flex-col justify-center items-center xl:space-x-5 lg:space-y-0 space-y-3">
        <a href="/aviso-de-privacidad" className="text-white underline sm:text-base text-sm">
          Aviso de privacidad
        </a>
        <a href="/terminos-y-condiciones" className="text-white underline sm:text-base text-sm">
          Términos y condiciones
        </a>
      </div>
    </footer>
  );
}
