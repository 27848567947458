import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

export const SEO = ({ title, description }) => {
  const location = useLocation();
  const defaultTitle = "El Medio de Medios";
  const defaultDescription =
    "Sitio web de análisis de noticias de medios en México y sobre México.";
  const defaultCanonical = "https://www.elmediodemedios.com";

  return (
    <Helmet>
      <title>{title ? `${defaultTitle} | ${title}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <link
        rel="canonical"
        href={`${defaultCanonical}${location.pathname}`}
      />

      <meta property="og:title" content={title || defaultTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:url"
        content={`${defaultCanonical}${location.pathname}`}
      />
      <meta
        property="og:image"
        content="https://www.elmediomedios.com/image.jpg"
      />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "El Medio de Medios",
          url: "https://www.elmediodemedios.com",
          logo: "https://www.elmediodemedios.com/logo.png",
        })}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
};
