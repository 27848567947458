import { BrowserRouter, Route, Routes} from "react-router-dom";
import { Navbar } from "./components/app/Navbar";
import Home from "./pages/Home";
import Terminos from "./pages/Terminos";
import Privacidad from "./pages/Privacidad";
import Error404 from "./pages/Error404";
import { ZettabyteForm } from "./components/home/suscription/suscriptionForm/ZettabyteForm";
import { AgendaForm } from "./components/home/suscription/suscriptionForm/AgendaForm";
import { SuccesSuscription } from "./components/home/suscription/statusPages/SuccesSuscription";
import { CancelSuscription } from "./components/home/suscription/suscriptionActions/CancelSuscription";
import { ProtectedRoutes } from "./middleware/ProtectedRoutes";
import { AgendaNacional } from "./components/publishers/AgendaNacional";
import { CirculoRojo } from "./components/publishers/CirculoRojo";
import { Mercados } from "./components/publishers/Mercados";
import { Zettabyte } from "./components/publishers/Zettabye";
import { Mananeras } from "./components/publishers/Mananeras";
import { HelmetProvider } from "react-helmet-async";
import { AgendaTest } from "./components/publishers/testing/AgendaTest";
import { ZettabyteTest } from "./components/publishers/testing/ZettabyteTest";
import ReactGA from "react-ga4";
import { ComingSoon } from "./pages/ComingSoon";
import { GoogleAnalytics } from "./seo/GoogleAnalytics";

ReactGA.initialize("G-WNRNEEQ6EF");

function App() {
    return (
    <>
      <HelmetProvider>
        <BrowserRouter>
        <GoogleAnalytics />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publicaciones/*" element={<PublisherLayout />} />
            <Route path="/suscripcion/*" element={<SuscripctionLayout />} />
            <Route path="/terminos-y-condiciones" element={<Terminos />} />
            <Route path="/aviso-de-privacidad" element={<Privacidad />} />
            <Route path="/proximamente/*" element={<ComingSoonLayout />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

const ComingSoonLayout = () => {
  return (
    <Routes>
      <Route
        path="agenda-nacional"
        element={<ComingSoon tittle="Agenda Nacional" />}
      />
      <Route path="zettabyte" element={<ComingSoon tittle="Zettabyte" />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const PublisherLayout = () => {
  return (
    <Routes>
      <Route path="agenda-nacional" element={<AgendaNacional />} />
      <Route path="agenda-nacional-testing" element={<AgendaTest />} />
      <Route path="circulo-rojo" element={<CirculoRojo />} />
      <Route path="mercados" element={<Mercados />} />
      <Route path="zettabyte" element={<Zettabyte />} />
      <Route path="zettabyte-testing" element={<ZettabyteTest />} />
      <Route path="mananeras" element={<Mananeras />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const SuscripctionLayout = () => {
  return (
    <Routes>
      <Route path="zettabyte" element={<ZettabyteForm />} />
      <Route path="agenda-nacional" element={<AgendaForm />} />
      <Route path="pago-exitoso" element={<SuccesSuscription />} />
      <Route
        path="cancelar-suscripcion/:paramURL"
        element={
          <ProtectedRoutes storageKey="emailCancel" expiredTime={300000}>
            <CancelSuscription />
          </ProtectedRoutes>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
export default App;
