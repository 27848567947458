import { useState } from "react";
import { postCancelURL } from "../../utils/ConstantsURL";

export const usePostCancelSuscription = () => {
  const [serverError, setServerError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const userEmail = sessionStorage.getItem("emailCancel");

  const sendData = async (dataForm, reset) => {
    setLoading(true);
    try {
      const response = await fetch(postCancelURL(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...dataForm, userEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        reset();
        setServerError(null);
        sessionStorage.removeItem("emailCancel");
        sessionStorage.removeItem("priceIdCancel");
        setShowModal(true);
        setLoading(false);
      } else {
        setServerError(data.message);
        setShowModal(false);
        setLoading(false);
      }
    } catch (error) {
      setServerError("Ocurrió un error, por favor intenta de nuevo.");
      setShowModal(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { sendData, loading, serverError, showModal, setShowModal };
};
