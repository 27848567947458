import { useNavigate } from "react-router-dom";
import { useGetProducts } from "../../hooks/useFetch/useGetProducts";

export const SuscriptionHome = () => {
  const { products } = useGetProducts();
  const navigate = useNavigate();
  
  return (
    <section
      id="suscripcion"
      className="flex flex-col justify-center items-center bg-colorGray1 min-h-screen space-y-24 py-32 bg-plans"
    >
      <div>
        <h4 className="text-colorBluetext-center text-colorBlue text-center">
          Nuestros planes
        </h4>
        <h3 className="text-center">
          Tenemos los documentos perfectos para ti
        </h3>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 xl:gap-10 gap-5">
        {products.map((planes, index) => (
          <div
            key={index}
            className="border-4 border-colorBlue rounded-xl p-8 xl:w-[350px] w-full"
          >
            <div className="flex flex-col items-center">
              <h4 className="text-colorBlue font-semibold capitalize">
                {planes.name}
              </h4>
              {planes.prices.map((price, index) => (
                <h3 key={index}>
                  ${price.price / 100} {price.currency}/{price.interval === "month" ? "mes" : "año"}
                </h3>
              ))}
              <button
                onClick={() => {
                  const plan = planes.name.toLowerCase().replace(/\s/g, "-");
                  // navigate(`/suscripcion/${plan}`);
                  navigate(`/proximamente/${plan}`);
                  console.log(plan);
                }}
                className="btn-bg-blue text-white hover:text-colorBlue w-32 h-11 mt-5 mb-8"
              >
                Suscribirme
              </button>
            </div>
            <div>
              <p className="text-sm text-center">{planes.description}</p>
              <p className="font-semibold my-5 text-sm text-center">
                ¡Prueba gratis por 7 días!
              </p>
              {planes.benefits.map((benefit, index) => (
                <p key={index} className="text-sm text-center">
                  <i className="fas fa-circle-check text-colorBlue mr-2" />{" "}
                  {benefit}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
