export const Input = ({
    placeholder,
    type,
    name,
    textLabel,
    register,
    errors,
    containerInput,
    classLabel,
    classInput,
  }) => {
    return(
      <div className={`flex flex-col space-y-2 ${containerInput} w-full relative`}>
      <label
        htmlFor={name}
        className={`${classLabel} sm:text-lg text-base font-medium`}
      >
        {textLabel}
      </label>
      <input
        id={name}
        type={type}
        placeholder={placeholder}
        className={`${classInput} ${errors[name] ? "input-error" : ""}`}
        {...register(name, {
          required: `${textLabel} es requerido`,
          pattern: type === "email" && {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Correo electrónico no válido",
          },
        })}
      />
      {errors[name] && (
        <span className=" text-xs font-semibold text-[#EB5757] absolute -bottom-5">
          *{errors[name].message}
        </span>
      )}
      {errors.serverError && (
        <span className=" text-xs font-semibold text-[#EB5757] absolute -bottom-5">
          *{errors.serverError}
        </span>
      )}
    </div>
    )
  }

  export const InputNumber = ({
    placeholder,
    name,
    textLabel,
    register,
    errors,
    containerInput,
    classLabel,
    classInput,
  }) => {
  
    const handleInput = (event) => {
      const { value } = event.target;
      event.target.value = value.replace(/[^0-9]/g, '');
    };
  
    return (
      <div className={`flex flex-col space-y-2 ${containerInput} w-full relative`}>
        <label
          htmlFor={name}
          className={`${classLabel} sm:text-lg text-base font-medium`}
        >
          {textLabel}
        </label>
        <input
          id={name}
          type="text"
          maxLength={6}
          placeholder={placeholder}
          onInput={handleInput}
          className={`${classInput} ${errors[name] ? "input-error" : ""}`}
          {...register(name, {
            required: `${textLabel} es requerido`,
            minLength: {
              value: 6,
              message: "Código de verificación incompleto",
            },
          })}
        />
        
        {errors[name] && (
          <span className="text-xs font-semibold text-[#EB5757] absolute -bottom-5">
            *{errors[name].message}
          </span>
        )}
        {!errors[name] && errors.serverError && (
          <span className="text-xs font-semibold text-[#EB5757] absolute -bottom-5">
            *{errors.serverError}
          </span>
        )}
      </div>
    );
  };