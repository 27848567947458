import { useMemo } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { useGetBoletines } from "../../hooks/useFetch/useGetBoletines";
import { SEO } from "../../seo/SEO";

export const Mercados = () => {
  const reference = "MDM-Boletines-Mercados";
  const { pdfUrl, navigate } = useGetBoletines({ reference });
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const boletines = useMemo(
    () => [
      { id: 1, name: "Agenda Nacional", path: "agenda-nacional" },
      { id: 2, name: "Círculo Rojo", path: "circulo-rojo" },
      { id: 3, name: "Mercados", path: "mercados" },
      { id: 4, name: "Zettabyte", path: "zettabyte" },
      { id: 5, name: "Mañaneras", path: "mananeras" },
    ],
    []
  );

  return (
    <>
      <SEO
        title="Mercados - Publicaciones"
        description="Información relevante del ámbito económico y empresarial en México y el mundo"
      />
      <section className="min-h-screen flex flex-col justify-center items-center w-full md:space-y-10 space-y-5 md:pt-16 pt-24 md:pb-0 pb-10">
        <div>
          <p className="text-center">De lunes a viernes</p>
          <h1 className="text-center">Mercados</h1>
        </div>
        <div className="flex md:flex-row flex-col justify-center w-full lg:space-x-20 md:space-x-10 md:space-y-0 space-y-5">
          <div className="flex flex-col md:space-y-6 space-y-3 ">
            {boletines.map((boletin) => (
              <button
                key={boletin.id}
                disabled={boletin.name === "Mercados"}
                className={`${
                  boletin.name === "Mercados"
                    ? "bg-transparent text-colorBlue"
                    : "btn-bg-blue text-white"
                } border-2 border-colorBlue py-3 rounded-md xl:w-[370px] md:w-[300px] w-full lg:text-3xl text-xl hover:text-colorBlue font-oswald relative`}
                onClick={() => {
                  navigate(`/publicaciones/${boletin.path}`);
                }}
              >
                {boletin.name}
              </button>
            ))}
          </div>

          <div className="lg:w-[950px] md:w-[850px] md:h-[700px] sm:h-[600px] h-[500px] flex flex-col justify-center items-center relative">
            {pdfUrl ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div className="absolute top-5 md:bottom-5 md:top-auto z-20">
                <div className="flex items-center justify-center bg-black/80 rounded-lg py-1 sm:px-3 px-2 space-x-1">
                  <Toolbar>
                    {(props) => {
                      const {
                        Zoom,
                        ZoomIn,
                        ZoomOut,
                        GoToNextPage,
                        GoToPreviousPage,
                        EnterFullScreen,
                      } = props;
                      return (
                        <>
                          <div>
                            <ZoomOut />
                          </div>
                          <div>
                            <Zoom />
                          </div>
                          <div>
                            <ZoomIn />
                          </div>
                          <div>
                            <GoToPreviousPage />
                          </div>
                          <div>
                            <GoToNextPage />
                          </div>
                          <div className="sm:flex hidden">
                            <EnterFullScreen />
                          </div>
                        </>
                      );
                    }}
                  </Toolbar>
                </div>
                </div>
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[toolbarPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Worker>
            ) : (
              <p>PDF no disponible</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
