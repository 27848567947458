import { useForm } from "react-hook-form";
import { usePostCancelSuscription } from "../../../../hooks/useFetch/usePostCancelSuscription";
import { InputNumber } from "../../../common/elementForms/Inputs";
import { TemplateForm } from "../../../common/TemplateForm";
import { ModalConfirmationTemplate } from "../../../common/TemplateModal";
import { useSendCode } from "../../../../hooks/useFetch/usePostRequestSuscription";
import { motion } from "framer-motion";

export const CancelSuscription = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ mode: "onChange" });

  const {
    sendData,
    loading,
    serverError,
    showModal,
    setShowModal,
  } = usePostCancelSuscription();

  const { sendCode, showModalCode, setShowModalCode } = useSendCode();

  const email = sessionStorage.getItem("emailCancel");
  const priceId = sessionStorage.getItem("priceIdCancel");

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center md:py-4 py-8 px-6">
      <div className="sm:w-[320px] w-[220px] mb-10">
        <img
          src="/images/home/suscription/img-cancel.webp"
          alt="Imagen newslatter"
          className="object-cover w-full"
        />
      </div>

      <div className="space-y-7 flex flex-col justify-center items-center w-full">
        <h4 className="md:text-3xl text-2xl font-bold text-center text-colorBlue">
          Lamentamos saber que te vas
        </h4>

        <div className="space-y-1 flex flex-col items-center justify-center">
          <p className="md:text-base text-sm text-center">
            Esperamos que nuestro contenido te haya sido valioso para ti.
          </p>
          <p className="md:text-base text-sm text-center">
            Introduce el código de verificación enviado a tu correo electrónico.
            <br />
            Revisa tu
            <span className="font-semibold text-colorBlue">
              {" "}
              bandeja de entrada o spam.
            </span>
          </p>
        </div>
      </div>

      <div className="md:w-2/3 w-full flex flex-col justify-center items-center mt-10">
        <TemplateForm
          onSubmit={handleSubmit((data) => sendData(data, reset))}
          textButton="Cancelar suscripción"
          classButton="bg-colorYellow sm:w-[255px] w-[220px] text-colorGray"
          containerButton="flex w-full justify-center items-center"
          styleIsValid="hover:border-2 hover:border-colorGreen hover:bg-transparent hover:text-colorGreen"
          isValid={isValid}
          loading={loading}
        >
          <div>
            <InputNumber
              name="confirmationCode"
              placeholder="Escribe aquí el código de verificación"
              textLabel="Código de verificación"
              register={register}
              errors={{ ...errors, serverError }}
              classInput="input-line-blue text-colorGray text-center"
              classLabel="text-colorGreen text-center hidden"
              containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
            />
          </div>
        </TemplateForm>

        <button
          onClick={() =>
            sendCode({
              email,
              priceId,
            })
          }
          className="text-colorBlue underline mt-5"
        >
          Reenviar código
        </button>
      </div>

      <ModalConfirmationTemplate
        isOpen={showModalCode}
        onClose={() => setShowModalCode(false)}
        className="hidden"
      >
        <h2 className="text-2xl text-colorBlue font-semibold text-center">
          ¡Código enviado!
        </h2>
        <div className="mt-5">
          <p className="text-center text-base">
            Hemos enviado un nuevo código de verificación {" "}
            <span className="font-semibold text-colorBlue">
              nuevo código de verificación 
            </span>
            {" "} a tu correo electrónico.
          </p>
          <p className="text-center text-base">
            Revisa tu bandeja de{" "}
            <span className="font-semibold text-colorBlue">entrada o spam</span>
            .
          </p>
        </div>
      </ModalConfirmationTemplate>

      <ModalConfirmationTemplate
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        className="hidden"
      >
        <h2 className="text-2xl text-colorBlue font-semibold text-center">
          ¡Cancelación Exitosa!
        </h2>
        <div className="mt-5">
          <p className="text-center text-base">
            Gracias por ser parte de{" "}
            <span className="font-semibold text-colorBlue">
              El Medio de Medios
            </span>
          </p>
          <p className="text-center text-base">
            Siempre seras bienvenido de regreso cuando quieras.
          </p>
        </div>
        <div className="flex items-center justify-center w-full mt-10">
            <motion.a
              href="/"
              whileTap={{ scale: 0.9 }}
              className="btn-bg-blue text-sm w-44 h-11 flex items-center justify-center rounded-lg text-white hover:text-colorBlue"
            >
              Aceptar
            </motion.a>
          </div>
      </ModalConfirmationTemplate>
    </div>
  );
};
