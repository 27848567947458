import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBoletinesURL } from "../../utils/ConstantsURL";

export const useGetBoletines = ({ reference }) => {
    const [pdfUrl, setPdfUrl] = useState("");
    const navigate = useNavigate();
    
    useEffect(() => {
        const getDocumentURL = async () => {
            try {
                const response = await fetch(getBoletinesURL(reference)); 
                const data = await response.json();
        
                if (data?.length && data[0]?.pdf?.url) {
                    setPdfUrl(`https://backend.elmediodemedios.com${data[0].pdf.url}`);
                } else {
                    throw new Error("No PDF found for the selected boletín.");
                }
            } catch (error) {
                console.error("Error fetching PDF:", error.message);
                setPdfUrl("");
            }
        };
        getDocumentURL();
    }, [reference]);
    
    return { pdfUrl, navigate };
}
