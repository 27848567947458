export const privacyData = [
  {
    title: "RESPONSABLE DE LOS DATOS PERSONALES.",
    description: [
      {
        text: "“Oblekcomp, S.A.P.I. de C.V.” (en adelante “Oblek”) con domicilio en Calle Cadetes del 47 número 6, Piso 1 departamento 2, Colonia San Miguel Chapultepec II Sección, Alcaldía Miguel Hidalgo, código postal 11850, Ciudad de México, tiene la encomienda de proteger la información personal proporcionada por sus usuarios (en adelante “Datos Personales”) y es el responsable de su Tratamiento (término que se define más adelante) cuando sean recabados a través del sitio de Internet https://www.elmediodemedios.com (en adelante el “Sitio”), aplicación nativa, medios impresos, redes sociales y/o vía telefónica (en adelante “Otros Medios”).",
      },
    ],
  },
  {
    title: "FINALIDAD DEL TRATAMIENTO.",
    description: [
      {
        text: "Oblek podrá solicitar y/o recabar a través del Sitio y Otros Medios, Datos Personales de los usuarios para: (i) Contactar con el usuario; (ii) envío de comunicaciones electrónicas; (iii) notificación de nueva entradas; (iv) comunicación de ofertas comerciales; (v) anuncios de distintos productos; (vi) gestión de base de datos de suscriptores y usuarios activos; (vii) Suministro de contenidos en el blog, podcast, y canal de YouTube (en caso de aplicar); y (viii) dirigir su red de afiliados y comerciantes, y gestión de pagos de los mismos; así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien, cuando sea solicitado por autoridades competentes (en adelante “Tratamiento”). Oblek y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales, guardará confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).",
      },
    ],
  },
  {
    title: "DATOS PERSONALES A RECABAR.",
    description: [
      {
        text: "Nombre",
      },
      {
        text: "Apellido",
      },
      {
        text: "Ubicación",
      },
      {
        text: "Nacionalidad",
      },
      {
        text: "Ocupación",
      },
      {
        text: "Correo",
      },
      {
        text: "Usted no podrá acceder a los servicios para los que se requieren sus Datos Personales cuando estos no sean proporcionados, sin embargo, sí podrá tener acceso a los demás Servicios que no los requieran.",
      },
    ],
  },
  {
    title: "MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.",
    description: [
      {
        text: "Oblek cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por los usuarios. Oblek tomará las acciones, medidas y previsiones especiales que considere necesarias, a fin de salvaguardar el derecho a la protección de Datos Personales de menores de edad e incapaces. Si usted desea dejar de recibir mensajes promocionales vía correo electrónico y/o de forma impresa y/o vía telefónica por parte de Oblek puede solicitarlo por conducto de: hola@elmediodemedios.com",
      },
    ],
  },
  {
    title: "EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.",
    description: [
      {
        text: "El Sitio podría contener hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en la “World Wide Web” que, al ser utilizados por los usuarios, transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que los usuarios llegaren a proporcionar a través de estos portales o sitios de Internet no se encuentran contemplados por este Aviso de Privacidad y su Tratamiento no es responsabilidad de Oblek. Recomendamos a los usuarios verificar los Avisos de Privacidad desplegados y aplicables a estos portales y sitios de Internet.",
      },
      {
        text: "Oblek advierte a sus usuarios que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, foros de discusión, páginas de Internet personales, avisos clasificados, correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas. Oblek no será responsable por el uso, publicación, revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos a los usuarios ser cuidadosos y responsables sobre toda información de carácter personal que proporcionen en dichos medios.",
      },
    ],
  },
  {
    title: "TRANSFERENCIA DE DATOS PERSONALES.",
    description: [
      {
        text: "Oblek podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los usuarios, para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.",
      },
      {
        text: "De igual forma podrá revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales a proveedores, patrocinadores, publicistas, contratistas y/o socios para fines comerciales, como proporcionar datos estadísticos a anunciantes potenciales, enviar publicidad a los usuarios de acuerdo a sus intereses específicos y conducir investigaciones de mercado. Los terceros que reciban la información relacionada con los Datos Personales de los usuarios están obligados a cumplir con lo previsto en este Aviso de Privacidad, así como con los Términos y Condiciones del Sitio y de la legislación aplicable en México. El titular de los Datos Personales podrá otorgar su consentimiento a la transferencia de sus Datos Personales en los términos descritos en el presente Aviso al momento de registrarse al Sitio.",
      },
    ],
  },
  {
    title:
      "MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO.",
    description: [
      {
        text: "Los usuarios podrán solicitar el ejercicio de sus derechos a: (i) accesar a sus Datos Personales; (ii) rectificar sus Datos Personales cuando sean inexactos o incompletos; (iii) cancelar o dar de baja sus Datos Personales; (iv) oponerse por causa legítima al tratamiento de sus Datos Personales, y (v) revocar su consentimiento para el tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje de hacer uso de los mismos.",
      },
      {
        text: "Sin embargo es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al tratamiento o revocar su consentimiento, ya que es posible que por alguna obligación legal requiramos seguir tratando sus Datos Personales. El usuario deberá considerar que para ciertos fines, la revocación de su consentimiento implicará la suspensión del servicio o la conclusión de su relación con Oblek. El ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación del consentimiento que realicen los usuarios deberá realizarse previa solicitud a Oblek y contener al menos lo siguiente:",
      },
      {
        text: "El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;",
      },
      {
        text: "Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;",
      },
      {
        text: "La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición);",
      },
      {
        text: "Cualquier otro elemento o documento que facilite la localización de los Datos Personales.",
      },
      {
        text: "Para efecto de lo anterior Oblek pone a disposición de los usuarios el siguiente correo electrónico: hola@elmediodemedios.com",
      },
      {
        text: "Oblek dará respuesta a la solicitud de los usuarios en un plazo no mayor a 15 (quince) días hábiles contados a partir de la fecha en que reciba la petición correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto. El ejercicio por parte de los usuarios de los derechos antes mencionados deberá sujetarse a las leyes y reglamentos aplicables vigentes en México.",
      },
    ],
  },
  {
    title: "COOKIES Y WEB BEACONS.",
    description: [
      {
        text: "Con el objetivo de mejorar la experiencia de sus usuarios en el Sitio, Oblek podrá utilizar “cookies”. Para efectos del presente Aviso de Privacidad “cookies” se identificará como los archivos de texto de información que un sitio web transfiere al disco duro de la computadora de los usuarios con el objeto de almacenar ciertos registros y preferencias. Oblek podrá utilizar “cookies” para mejor entendimiento de la interacción de los usuarios con el Sitio y los servicios que se proporcionan. El Sitio puede permitir publicidad o funciones de terceros que envíen “cookies” a las computadoras de los usuarios. Mediante el uso de “cookies” no se identifica personalmente a los usuarios, únicamente a sus computadoras.",
      },
      {
        text: "Por su parte las “web beacons” son imágenes insertadas en una página de Internet o correo electrónico que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, horario de navegación, duración del tiempo de interacción en el Sitio, secciones consultadas, páginas de Internet accedidas previo a la nuestra y el tipo de navegador utilizado. Los usuarios podrán cambiar sus opciones a través de sus equipos de cómputo y/o navegadores para dejar de aceptar “cookies” y/o “web beacons” o bien confirmar si aceptan o no las mismas.",
      },
    ],
  },
  {
    title: "DATOS PERSONALES SENSIBLES.",
    description: [
      {
        text: "De conformidad con la legislación mexicana vigente, se conocen como “Datos Personales Sensibles” a “aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas, preferencia sexual”.",
      },
      {
        text: "En caso que Oblek llegue a requerir Datos Personales Sensibles de los usuarios, estos deberán proporcionar su consentimiento expreso.",
      },
    ],
  },
  {
    title: "CAMBIOS AL AVISO DE PRIVACIDAD.",
    description: [
      {
        text: "Oblek se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado a sus usuarios a través del Sitio. Una vez que se publique el Aviso de Privacidad en el Sitio entrará en vigor automáticamente.",
      },
      {
        text: "La fecha de última actualización del presente aviso es el 28 de octubre de 2020.",
      },
    ],
  },
];
