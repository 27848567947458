import { useState } from "react";
import { postCreateSessionURL } from "../../utils/ConstantsURL";

export const usePostCreateSession = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const sendData = async (formData, reset, id) => {
    setLoading(true);
    try {
      const response = await fetch(postCreateSessionURL(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, priceId:id }),
      });

      const data = await response.json();

      if (response.ok) {
        reset();
        setServerError(null);
        window.location.href = data.url;
      } else {
        setServerError(data.error);
      }
    } catch (error) {
      console.error("Error al enviar los datos", error);
      setServerError("Error al enviar los datos");
    } finally {
      setLoading(false);
    }
  };

  return { sendData, loading, serverError };
};
