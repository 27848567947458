import ReactModal from "react-modal";
import animationData from "../../assets/jsons/check.json";
import Lottie from "lottie-react";
import { motion } from "framer-motion";


export const ModalTemplate = ({ isOpen, onClose, children, className}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-70"
    >
      <div className="bg-white md:p-10 p-5 rounded-2xl w-full max-w-xl flex flex-col items-center justify-center space-y-12 relative">
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className={`absolute top-4 right-4 bg-colorGray1 hover:bg-colorBlue hover:text-white text-colorBlue rounded-full w-7 h-7 ${className}`}
        >
          <i className="fas fa-xmark text-base" />
        </motion.button>
        <div className="w-full flex flex-col items-center justify-center">
          {children}
        </div>
      </div>
    </ReactModal>
  );
};


export const ModalConfirmationTemplate = ({ isOpen, onClose, children,className }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={false}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-70"
    >
      <div className="bg-white p-10 rounded-2xl w-full max-w-xl flex flex-col items-center justify-center space-y-12 relative">
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className={`absolute top-4 right-4 bg-colorGray1 hover:bg-colorBlue hover:text-white text-colorBlue rounded-full w-7 h-7 ${className}`}
        >
          <i className="fas fa-xmark text-base" />
        </motion.button>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="mb-5 sm:w-32 w-20">
            <Lottie
              animationData={animationData}
              loop={false}
              autoPlay={true}
            />
          </div>
          {children}
        </div>
      </div>
    </ReactModal>
  );
};