import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const GoogleAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    const page = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: "pageview", page });
  }, [location]);

  return null;
};
