import { SEO } from "../seo/SEO";
import { termsData } from "../utils/data/TermsData";

export default function Terminos() {
  return (
    <>
      <SEO
        title="Términos y Condiciones"
        description="Revisa nuestros términos y condiciones"
      />
      <section className="min-h-screen sm:py-32 py-24 space-y-10">
        <div className="space-y-10">
          <h2 className="font-medium">Términos y condiciones</h2>
          <p className="text-justify">
            Este contrato describe los términos y condiciones generales (los
            "Términos y Condiciones") aplicables al uso de los contenidos,
            productos y servicios ofrecidos a través del sitio
            www.elmediodemedios.com (el "Sitio Web"), del cual es titular SR &
            Friends S.A. de C.V. ("SR & Friends"), una sociedad mercantil
            constituida de conformidad con las leyes de los Estados Unidos
            Mexicanos (“México”), la cual tiene su domicilio establecido en
            Calle Cadetes del 47 número 6, Piso 1, departamento 2, colonia San
            Miguel Chapultepec II Sección, Alcaldía Miguel Hidalgo, código
            postal 11850, Ciudad de México, México. Cualquier persona que desee
            acceder o hacer uso del Sitio Web o los servicios que en él se
            ofrecen (el “Usuario”), podrá hacerlo sujetándose a los presentes
            Términos y Condiciones. En todo caso, cualquier persona que no
            acepte los presentes Términos y Condiciones, deberá abstenerse de
            utilizar el Sitio Web y/o adquirir los productos y servicios que en
            su caso sean ofrecidos.
          </p>
        </div>

        <div className="space-y-10">
          {termsData.map((item, index) => (
            <div key={index} className="space-y-10">
              <h4 className="font-medium">{item.title}</h4>
              <div className="space-y-0">
                {item.description.map((description, index) => (
                  <div key={index}>
                    <p className="text-justify">{description.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
