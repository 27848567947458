import { useMemo } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { useGetBoletines } from "../../../hooks/useFetch/useGetBoletines";

export const AgendaTest = () => {
  const reference = "MDM-Boletines-Agenda-Nacional";
  const { pdfUrl, navigate } = useGetBoletines({ reference });
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const boletines = useMemo(
    () => [
      { id: 1, name: "Agenda Nacional" },
      { id: 2, name: "Circulo Rojo" },
      { id: 3, name: "Mercados" },
      { id: 4, name: "Zettabyte" },
      { id: 5, name: "Mañaneras" },
    ],
    []
  );

  return (
    <>
      <section className="min-h-screen flex flex-col justify-center items-center w-full md:space-y-10 space-y-5 md:pt-20 pt-24 md:pb-0 pb-20">
        <div>
          <p className="text-center">De lunes a domingo</p>
          <h1 className="text-center">Agenda Nacional</h1>
        </div>
        <div className="flex md:flex-row flex-col justify-center w-full md:space-x-20 md:space-y-0 space-y-5">
          <div className="flex flex-col md:space-y-6 space-y-3 ">
            {boletines.map((boletin) => (
              <button
                key={boletin.id}
                disabled={boletin.name === "Agenda Nacional"}
                className={`${
                  boletin.name === "Agenda Nacional"
                    ? "border-2 border-colorBlue bg-transparent text-colorBlue"
                    : "btn-bg-blue text-white"
                } py-3 rounded-md xl:w-[370px] md:w-[300px] w-full lg:text-3xl text-xl hover:text-colorBlue font-oswald relative`}
                onClick={() => {
                  const document = boletin.name
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/ñ/g, "n");
                  navigate(`/publicaciones/${document}`);
                }}
              >
                {boletin.name}
                {(boletin.name === "Zettabyte" ||
                  boletin.name === "Agenda Nacional") && (
                  <i className="fas fa-crown text-base absolute right-5 top-1/2 -translate-y-1/2" />
                )}
              </button>
            ))}
          </div>

          <div className="md:w-[850px] md:h-[700px] h-[500px] flex flex-col justify-center items-center relative">
            <div className="w-full h-full bg-white/50 backdrop-blur-md absolute z-40 flex flex-col justify-center items-center space-y-5">
              <h4 className="font-semibold">¡Mantente informado!</h4>
              <p className="text-center font-normal max-w-xl text-base px-3">
                Recibe la{" "}
                <span className="text-colorBlue font-bold">
                  Agenda Nacional
                </span>{" "}
                en tu correo y no te pierdas lo más relevante en política y
                seguridad nacional e internacional.
              </p>
              <p className="text-center font-normal max-w-xl text-base px-3">
                Suscríbete y disfruta de{" "}
                <span className="text-colorBlue font-bold">
                  15 días gratis.
                </span>
              </p>
              <a
                href="/suscripcion/agenda-nacional"
                className="btn-bg-blue text-white hover:text-colorBlue transition-colors ease-in-out duration-300 w-40 h-11 flex justify-center items-center rounded-lg"
              >
                Suscribirme
              </a>
            </div>

            {pdfUrl ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div className="items-center bg-black/80 rounded-lg p-1 flex absolute top-4 z-20">
                  <Toolbar>
                    {(props) => {
                      const {
                        Zoom,
                        ZoomIn,
                        ZoomOut,
                        GoToNextPage,
                        GoToPreviousPage,
                      } = props;
                      return (
                        <>
                          <div style={{ padding: "0px 2px" }}>
                            <ZoomOut />
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <Zoom />
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <ZoomIn />
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <GoToPreviousPage />
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <GoToNextPage />
                          </div>
                        </>
                      );
                    }}
                  </Toolbar>
                </div>
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[toolbarPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Worker>
            ) : (
              <p>PDF no disponible</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
