import { useEffect, useState } from "react";
import { Logotipo } from "../../assets/identity/Logotipo";
import { useLocation } from "react-router-dom";
import { CloseIcon, MenuIcon } from "../../assets/icons/MenuIcons";
export const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`w-full py-6 fixed z-50  ${
        scrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      <nav className="flex justify-between items-center w-full relative">
        <div className="sm:w-48 w-40">
          <a  aria-label="Ir al inicio" href="/" rel="noreferrer noopener">
            <Logotipo fill="#000" />
          </a>
        </div>
        <div className="space-x-10 sm:flex items-center hidden">
          <Enlaces to={"/"}>Inicio</Enlaces>
          <Enlaces to={"/publicaciones/agenda-nacional"}>Publicaciones</Enlaces>
        </div>

        <div className="sm:hidden flex">
          <button aria-label="Abrir menu" onClick={() => setShowMenu(!showMenu)}>
            <MenuIcon />
          </button>
        </div>
      </nav>

      {showMenu && (
        <div className="sm:hidden fixed inset-0 overflow-hidden w-full min-h-screen bg-black  bg-opacity-60 z-50">
          <nav className=" bg-white pt-6">
            <div className="flex justify-between items-center px-[6%]">
              <div className="sm:w-48 w-40">
                <a  aria-label="Ir al inicio" href="/" rel="noreferrer noopener">
                  <Logotipo fill="#000" />
                </a>
              </div>
              <button aria-label="Cerrar menu" onClick={() => setShowMenu(!showMenu)}>
                <CloseIcon />
              </button>
            </div>

            <div className="flex flex-col justify-end pt-10">
              <Enlaces to={"/"}>Inicio</Enlaces>
              <Enlaces to={"/publicaciones/agenda-nacional"}>
                Publicaciones
              </Enlaces>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

const Enlaces = ({ to, children }) => {
  const location = useLocation();
  const isPublisher = location.pathname.startsWith("/publicaciones");

  return (
    <a
      href={to}
      className={`hover:text-colorBlue hover:border-b-2 hover:border-colorBlue transition-colors font-open uppercase duration-300 text-center text-base relative ${
        location.pathname === to ||
        (to.startsWith("/publicaciones") && isPublisher)
          ? "sm:text-colorBlue sm:border-b-2 sm:border-colorBlue sm:bg-transparent sm:py-0 py-4 bg-colorBlue text-white w-full font-bold"
          : "font-semibold sm:py-0 py-4"
      }`}
    >
      {children}
    </a>
  );
};
