import { SEO } from "../seo/SEO";
import { privacyData } from "../utils/data/PrivacyData";

export default function Privacidad() {
  return (
    <>
      <SEO
        title="Aviso de Privacidad"
        description="Consulta nuestro aviso de privacidad"
      />
      <section className="min-h-screen sm:py-32 py-24 space-y-10">
        <h2 className="font-medium">Aviso de privacidad</h2>

        <div className="space-y-10">
          {privacyData.map((item, index) => (
            <div key={index} className="space-y-10">
              <h4 className="font-medium">{item.title}</h4>
              <div>
                {item.description.map((description, index) => (
                  <div key={index}>
                    <p className="text-justify">{description.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
