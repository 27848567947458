//URL de  desarrollo
// const baseURLFetch = 'http://localhost:3003'

//URL de produccion prueba
// const baseURLFetch = 'https://elmediodemedios.com/api'

//URL de produccion
const baseURLFetch = 'https://elmediodemedios.com/api'

// URLs fetch GET
export const getProductsURL = () => {
    return `${baseURLFetch}/product`
}


// URLs fetch POST

export const postCreateSessionURL = () => {
    return `${baseURLFetch}/payment/create-session`
}

export const postRequestCancelURL = () => {
    return `${baseURLFetch}/subscription/request-cancel`
}

export const postCancelURL = () => {
    return `${baseURLFetch}/subscription/verify-code-cancel`
}

export const getBoletinesURL = (documentReference) => {
    return `https://backend.elmediodemedios.com/archivos?_sort=created_At:DESC&categorias.nombre_eq=${documentReference}`
}