import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export const ProtectedRoutes = ({
    children,
    storageKey,
    expiredTime,
  }) => {
    const { paramURL } = useParams();
    const navigate = useNavigate();
  
    const constStorage = sessionStorage.getItem(storageKey);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        sessionStorage.removeItem(storageKey);
        navigate("/");
      }, expiredTime);
      return () => clearTimeout(timer);
    }, [navigate, storageKey, expiredTime]);
  
    if (!constStorage) {
      return <Navigate to={`/`} />;
    }
  
    return paramURL === constStorage ? children : <Navigate to="*" />;
  };
  