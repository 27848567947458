import { SEO } from "../seo/SEO";

export const ComingSoon = ({ tittle }) => {
  return (
    <>
      <SEO
        title={`Próximamente ${tittle}`}
        description={`Próximamente suscripción a ${tittle}`}
      />
      <section className="bg-home min-h-screen flex flex-col justify-center items-center py-32 px-6">
        <p className="text-center font-normal">Sitio en construcción</p>
        <h3 className="text-center">Próximamente</h3>
        <h1 className="text-center xl:text-7xl md:text-6xl sm:text-5xl text-4xl text-colorBlue mb-20 mt-5">
          Suscripción a {tittle}
        </h1>

        <a
          href="/"
          className="h-11 btn-bg-blue font-semibold text-white uppercase w-44 rounded-xl flex items-center justify-center transition-colors duration-300 hover:text-colorBlue"
        >
          Inicio
        </a>
      </section>
    </>
  );
};
