import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/buttons.css';
import './assets/styles/backgrounds.css';
import './assets/styles/inputs.css';
import './assets/styles/visorPDF.css';
import App from './App';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <App />
 // </React.StrictMode>
);
