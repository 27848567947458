import { useEffect, useState } from "react";
import { getProductsURL } from "../../utils/ConstantsURL";
import { useNavigate } from "react-router-dom";

export const useGetProducts = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const getProducts = async () => {
        try {
            const response = await fetch(getProductsURL(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            });
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.log(error);
        }
        };
    
        getProducts();
    }, []);
    
    return { products, navigate };
}