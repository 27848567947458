import { BannerHome } from "../components/home/BannerHome";
import { FAQSHome } from "../components/home/FAQsHome";
import { HeroHome } from "../components/home/HeroHome";
import { ReasonHome } from "../components/home/ReasonHome";
import { SuscriptionHome } from "../components/home/SuscriptionHome";
import Footer from "../components/app/Footer.jsx";
import { SEO } from "../seo/SEO.jsx";

export default function Home() {
  return (
    <>
      <SEO
        description="El Medio de Medios es un sitio web de análisis de noticias de medios en México y el el mundo."
      />
      <main>
        <HeroHome />
        <BannerHome />
        <ReasonHome />
        <SuscriptionHome />
        <FAQSHome />
        <Footer />
      </main>
    </>
  );
}
