import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const SuccesSuscription = () => {
    const navigate = useNavigate();
    return (
        <>
          <div className=" min-h-screen w-full flex flex-col items-center justify-center md:py-4 py-8 px-6 bg-success bg-success">
    
            <div className="sm:w-[330px] w-[230px] mb-10">
              <img
                src="/images/home/suscription/img-succes.webp"
                alt="Imagen newslatter"
                className="object-cover w-full"
              />
            </div>
    
            <div className="space-y-8 flex flex-col justify-center items-center w-full mb-16">
              <h4 className="md:text-4xl sm:text-3xl text-2xl font-bold text-center text-colorBlue">
                ¡Bienvenido! <br/> <span className="text-colorGray">Tu pago se ha realizado con éxito.</span>
              </h4>
    
              <p className="md:text-base text-sm text-center">
                Recibirás de
                <span className="text-colorBlue font-bold"> lunes a viernes </span>
                 tu producto en tu correo electrónico 
                <br />
                todos los días. Por favor revisa tu bandeja de <span className="text-colorBlue font-bold">entrada o spam</span> .
              </p>
            </div>
    
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                navigate("/");
              }}
              className="btn-bg-yellow h-11 w-[250px] rounded-lg text-colorGray"
            >
              Regresar al Medio de medios
            </motion.button>
          </div>
        </>
      );

}