import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRequestCancelURL } from "../../utils/ConstantsURL";

export const usePostRequestSuscription = () => {
  const [serverError, setServerError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendData = async ({ formData, typeModal, reset }) => {
    try {
      setLoading(true);
      const response = await fetch(
        typeModal === "actualizar" ? "" : postRequestCancelURL(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        reset();
        setServerError(null);
        setLoading(false);
        navigate(
          typeModal === "actualizar"
            ? `/actualizar-email/${data.email}`
            : `/suscripcion/cancelar-suscripcion/${data.email}`
        );
        sessionStorage.setItem(
          typeModal === "actualizar" ? "emailUpdate" : "emailCancel",
          data.email
        );

        sessionStorage.setItem(
          typeModal === "actualizar" ? "emailUpdate" : "priceIdCancel",
          formData.priceId
        );
      } else {
        setServerError(data.error);
        setLoading(false);
      }
    } catch (error) {
      setServerError("Ocurrió un error, por favor intenta de nuevo.");
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    sendData,
    loading,
    serverError,
  };
};

export const useSendCode = () => {
  const [loading, setLoading] = useState(false);
  const [showModalCode, setShowModalCode] = useState(false);

  const handleModal = () => {
    setTimeout(() => {
      setShowModalCode(false);
    }, 4000);
  };

  const sendCode = async ({ email, priceId }) => {
    setLoading(true);
    try {
      const response = await fetch(postRequestCancelURL(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, priceId }),
      });

      if (response.ok) {
        setShowModalCode(true);
        handleModal();
        setLoading(false);
      } else {
        throw new Error("Error al enviar el código");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { sendCode, loading, showModalCode, setShowModalCode };
};
