export const HeroHome = () => {
  return (
    <section className="md:min-h-screen min-h-[90vh] flex flex-col justify-center items-center space-y-10 bg-home py-32">
      <div>
        <h4 className="text-center text-colorBlue sm:text-2xl text-lg">
          Boletines
        </h4>
        <h1 className="max-w-5xl text-center">
          Expertos en monitoreo de medios. Más de 25 años integrando y
          analizando data para decisiones estratégicas, claras y efectivas.
        </h1>
      </div>
      <a
        href="#suscripcion"
        className="btn-bg-blue text-white hover:text-colorBlue transition-colors ease-in-out duration-300 w-40 h-11 flex justify-center items-center rounded-lg"
      >
        Suscribete
      </a>
    </section>
  );
};
