export const gridHomeData = [
  {
    title: "Monitoreo experto en medios",
    description:
      " Accede a más de 25 años de experiencia en análisis de datos y mantente al tanto de las noticias políticas, financieras y tecnológicas con información precisa y relevante.",
  },
  {
    title: "Ahorra tiempo y energía",
    description:
      "Recibe un panorama general y completo de temas de interés directamente en tu correo. No pierdas tiempo buscando en múltiples fuentes.",
  },
  {
    title: "Información confiable y objetiva",
    description:
      "Correos exhaustivos con análisis claro y objetivo. Te presentamos los hechos de manera neutral, para que formes tu propia opinión.",
  },
  {
    title: "Cobertura amplia y actualizada",
    description:
      "Mantente al día con las últimas actualizaciones y contenido sintetizado y sobre economía, política, finanzas, y más.",
  },
  {
    title: "Perspectiva global e integral",
    description:
      "Monitoreo de radio, televisión, prensa y web para un panorama completo y detallado de temas relevantes a nivel nacional e internacional.",
  },
  {
    title: "Personalización de contenido",
    description:
      "Personaliza tu suscripción y recibe solo lo que te interesa. ",
  },
];

export const plansSuscriptionData = [
  {
    title: "Zettabyte",
    price: "$99 mxn/mes",
    ofert: "¡Prueba gratis por 15 días!",
    description:
      "Información para tecnoadictos, cultura digital, big data, IA y nuevas tecnologías",
    benefits: ["Recibelo a las 10 a.m", "Recibelo a tu correo electronico"],
  },
];

export const faqsHomeData = [
  {
    title: "¿Cómo funciona el monitoreo de medios?",
    description:
      "Nuestro equipo de expertos analiza y sintetiza la información de los medios de comunicación más importantes para entregarte un resumen claro y objetivo de los hechos más relevantes del día.",
    list: ["- Síntesis y noticias más relevantes.", "- Análisis coyuntural."],
  },
  {
    title: "¿Por qué existe el Medio de Medios?",
    description:
      "MDM inicia como un newsletter informativo para altos directivos y cargos de Gobierno que necesitan información precisa, analítica y en un tiempo de lectura optimizado. Así creamos un producto de consumo masivo con esos mismos principios:",
    list: [
      "- Información que importa.",
      "- Resúmenes de panorama completo para lectura fácil.",
      "- Análisis interpretativo del panorama nacional y el panorama interncional.",
    ],
  },
  {
    title: "¿El Medio de Medios es de izquierdas o de derechas?",
    description:
      "Ninguno. El Medio de Medios pretender ser una fuente imparcial de información. Nuestras síntesis tienen distintas fuentes siempre indicadas en el artículo para poder ofrecer un panorama amplio y lo más objetivo posible.",
  },
  {
    title: "¿Cuáles son los medios que usan como fuente?",
    description:
      "El Medio de Medios consulta toda la base de datos de Oblek Company que se compone de más de 500 medios de comunicación. ",
  },
  {
    title: "¿Puedo contactar con El Medio de Medios si tengo algún problema?",
    description:
      "¡Claro! Escríbenos a hola@elmediodemedios.com y te contestaremos lo antes posible. ",
  },
  {
    title: "¿Se puede cancelar la suscripción?",
    description:
      "¡Claro! Puedes darte de baja o cancelar tu cuenta en cualquier momento.",
      button: [
        {
          text: "Cancelar suscripción",
          type: "cancelar",
        }
      ]
  },
];
