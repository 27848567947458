export const Isotipo = ({fill}) => {
  return (
    <svg
      viewBox="0 0 50 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.3992 3.93716L50 3.04627V0H47.3992H46.9537H39.2566H35.1806L24.9976 25.4574L14.8146 0H2.59603H0V3.04627L4.42571 4.5694L18.8907 40.7319H22.1669H22.962L35.1806 10.183V32.5845V36.7947L32.5845 37.6856V40.7319H35.1806H47.3992H50V37.6856L47.3992 36.7947V32.5845V3.93716Z"
        fill={fill}
      />
      <path
        d="M6.37992 28.6474L4.69394 24.4373H2.59603V27.2536V28.6474V32.5846V36.7948L0 37.6857V40.7319H2.59603H6.37992H8.97596V37.6857L6.37992 36.7948V28.6474Z"
        fill="#FA4443"
      />
    </svg>
  );
};
