import { useState } from "react";
import { faqsHomeData } from "../../utils/data/Data";
import { motion } from "framer-motion";
import { ModalTemplate } from "../common/TemplateModal";
import { Input } from "../common/elementForms/Inputs";
import { TemplateForm } from "../common/TemplateForm";
import { useForm } from "react-hook-form";
import { usePostRequestSuscription } from "../../hooks/useFetch/usePostRequestSuscription";
import { useGetProducts } from "../../hooks/useFetch/useGetProducts";

export const FAQSHome = () => {
  const [showFAQS, setShowFAQS] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [typeModal, setTypeModal] = useState("");

  return (
    <section className="min-h-screen sm:py-24 py-16">
      <h4 className="text-colorBlue text-center">Resolvemos tu duda</h4>
      <h3 className="text-center lg:mb-14">Preguntas frecuentes</h3>
      <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-10">
        <div className="">
          <img
            src="/images/home/img-faqs.webp"
            alt="Preguntas frecuentes"
            width="450"
            height="300"
            loading="lazy"
            className="xl:w-[450px] md:w-[400px] sm:w-[380px] w-full h-auto object-cover"
          />
        </div>

        <div className="2xl:max-w-4xl xl:max-w-xl lg:max-w-lg space-y-8">
          {faqsHomeData.map((faq, index) => (
            <div key={index} className="space-y-3">
              <div className="flex justify-between items-center space-x-3">
                <h5>{faq.title}</h5>
                <button
                  aria-label="Desplegar"
                  onClick={() => setShowFAQS(showFAQS === index ? null : index)}
                >
                  <motion.i
                    animate={{
                      rotate: showFAQS === index ? 0 : -180,
                    }}
                    className="fas fa-angle-up text-xl"
                  />
                </button>
              </div>
              <motion.div
                animate={{
                  opacity: showFAQS === index ? 1 : 0,
                  height: showFAQS === index ? "auto" : 0,
                  overflow: showFAQS === index ? "visible" : "hidden",
                }}
              >
                <p>{faq.description}</p>
                {faq.list && (
                  <ul>
                    {faq.list.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                )}
                {faq.button?.map((button, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setShowModalConfirm(true);
                      setTypeModal(button.type);
                    }}
                    className="text-[#0064E5] underline font-work sm:text-base text-[16px] h-auto"
                  >
                    {button.text}
                  </button>
                ))}
              </motion.div>
            </div>
          ))}
        </div>
      </div>

      <ModalAnswer
        isOpen={showModalConfirm}
        onClose={() => setShowModalConfirm(!showModalConfirm)}
        onConfirm={() => {
          setShowModalConfirm(!showModalConfirm);
          setShowModalEmail(!showModalEmail);
        }}
        typeModal={typeModal}
      />

      <ModalEmail
        isOpen={showModalEmail}
        onClose={() => {
          setShowModalEmail(!showModalEmail);
          setShowModalConfirm(!showModalConfirm);
        }}
        typeModal={typeModal}
      />
    </section>
  );
};

const ModalAnswer = ({ isOpen, onClose, onConfirm, typeModal }) => {
  return (
    <ModalTemplate isOpen={isOpen} onClose={onClose}>
      <h2
        className={`sm:text-2xl text-xl text-colorBlue font-semibold text-center`}
      >
        ¿Estás seguro que quieres{" "}
        {typeModal === "actualizar"
          ? " actualizar tu correo?"
          : "cancelar tu suscripción?"}
      </h2>
      <div className="flex sm:flex-row flex-col sm:space-x-6 sm:space-y-0 space-y-4 mt-16 ">
        <button
          onClick={onClose}
          className="btn-bg-blue h-11 w-[190px] text-white hover:text-colorBlue "
        >
          No, regresar
        </button>
        <button
          onClick={onConfirm}
          className="bg-colorGray1 hover:border-2 hover:border-colorBlue hover:bg-transparent w-[190px]"
        >
          Si, {typeModal}
        </button>
      </div>
    </ModalTemplate>
  );
};

const ModalEmail = ({ isOpen, onClose, typeModal }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const { sendData, loading, serverError } = usePostRequestSuscription();
  const { products } = useGetProducts();

  return (
    <>
      <ModalTemplate isOpen={isOpen} onClose={onClose}>
        <div className="space-y-6">
          <h2 className="sm:text-2xl text-xl text-colorBlue font-semibold text-center">
            Ingresa el correo donde recibes el Newsletter
          </h2>
          <p className="text-sm text-center">
            Por favor, selecciona tu plan e ingresa tu correo para verificar que
            eres el propietario de la suscripción y completar el proceso de{" "}
            <span className="font-semibold text-colorBlue uppercase">
              {typeModal === "actualizar" ? "actualización" : "cancelación"}.
            </span>
          </p>
        </div>
        <div className="mt-6 ">
          <TemplateForm
            onSubmit={handleSubmit((data) =>
              sendData({ formData: data, typeModal, reset })
            )}
            textButton="Entendido"
            classButton="bg-colorBlue sm:w-[255px] w-[220px] text-white"
            containerButton="flex w-full justify-center items-center"
            styleIsValid="hover:border-2 hover:border-colorBlue hover:bg-transparent hover:text-colorBlue"
            isValid={isValid}
            loading={loading}
          >
            <div>
              <div className="flex space-x-5">
                {products.map((product, productIndex) => (
                  <div
                    key={productIndex}
                    className="flex items-center space-x-1"
                  >
                    {product.prices.map((price, priceIndex) => (
                      <div
                        key={priceIndex}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="radio"
                          id={price.id}
                          name="priceId"
                          value={price.id}
                          {...register("priceId")}
                        />
                        <label htmlFor={price.id} className="text-sm">
                          {product.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <Input
                name="email"
                type="email"
                placeholder="Escribe aquí tu correo electrónico"
                textLabel="Correo electrónico"
                register={register}
                errors={{ ...errors, serverError }}
                classInput="input-line-blue text-colorGray"
                classLabel="hidden"
                containerInput="xl:w-[380px] lg:w-[370px] md:w-[330px]"
              />
            </div>
          </TemplateForm>
        </div>
      </ModalTemplate>
    </>
  );
};
