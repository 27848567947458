import { SEO } from "../seo/SEO";

export default function Error404() {
  return (
    <>
    <SEO title="Error 404" description="Página no encontrada" />
    <section className="bg-home flex flex-col justify-center items-center min-h-screen ">
      <div className="flex flex-col items-center">
        <h2 className="lg:text-6xl sm:text-5xl text-4xl font-medium ">
          ERROR
        </h2>
        <h1 className="text-colorBlue font-bold lg:text-[200px] sm:text-[180px] text-8xl text-center">
          404
        </h1>
        <h3 className="lg:text-2xl sm:text-2xl text-lg font-medium sm:mb-20 mb-6 text-center">
          No se encontró la página.
        </h3>

        <a href="/" className="btn-bg-blue rounded-[10px] font-semibold w-40 h-11 flex items-center justify-center transition-colors ease-in duration-300 text-white hover:text-colorBlue">
          Inicio
        </a>
      </div>
    </section>
    </>
  );
}
