import { gridHomeData } from "../../utils/data/Data";

export const ReasonHome = () => {
  return (
    <section className="min-h-screen flex flex-col justify-center items-center sm:space-y-20 space-y-10 sm:py-32 py-20">
      <div>
          <h4 className="text-center text-colorBlue">¿Por qué suscribirte?</h4>
          <h3 className="max-w-5xl text-center">
            Ofrecemos un panorama general, ahorrarás tiempo y lo
            mejor: nunca influenciaremos tu opinión
          </h3>
        </div>

        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-14 gap-8">
            {gridHomeData.map((item, index) => (
              <div key={index} className="space-y-5">
                <h4 className="sm:text-left text-center">{item.title}</h4>
                <p>{item.description}</p>
              </div>
            ))}
        </div>
    </section>
  );
};
